import {
  ICommandBarItemProps, IconButton, SelectionMode, Stack, Text, CommandBar, Panel, DetailsList, PanelType, SearchBox, Link, MessageBarType
} from '@fluentui/react';
import { useBoolean } from "@fluentui/react-hooks";
import { useContext, useEffect, useState } from 'react'
import { appRoles } from '../../authConfig';
import { scrollStackItemStyles } from '../../common/styles/StackStyles';
import {
  createWorkOrderAssignment, getChartsData, getContractors, getWorkOrders, getWorkOrderStatuses, IContractor, IPagedCollection, IResendWorkOrderResponse, IWorkOrder, IWorkOrderAssignmentRequest, IWorkOrderStatuses, resendWorkOrder
} from '../../services/assetServices'
import AppContext from '../AppContext';
import WorkItems from './WorkItems';
import { useAtomValue, useSetAtom } from 'jotai';
import { clearMessageAtom, errorMessageAtom, isInProgressAtom, messageBarAtom } from '../../atoms/messageBarAtoms';
import { profileDataAtom } from '../../atoms/authAtoms';
import { notifAtom } from '../../atoms/notifAtoms';

const WorkOrders = () => {
  const context = useContext(AppContext);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const clearMessage = useSetAtom(clearMessageAtom);
  const setMessageBar = useSetAtom(messageBarAtom);

  const profileData = useAtomValue(profileDataAtom);

  const [workOrderStatuses, setWorkOrderStatuses] = useState<IWorkOrderStatuses[]>([]);
  const [allItems, setWorkOrders] = useState<IWorkOrder[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState({ no: 1, search: "" });
  const [shimmered, setShimmered] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);

  const fetchData = async () => {
    context.setSelectedTab("Work orders");
    const abortController = new AbortController();
    setIsInProgress(true);

    try {
      const abortController = new AbortController();

      const woStatuses: IWorkOrderStatuses[] = await getWorkOrderStatuses(abortController);
      setWorkOrderStatuses(woStatuses);
      const data: IPagedCollection<IWorkOrder> = await getWorkOrders(abortController, 10, page.no);
      setWorkOrders(data?.items?.map(contractor => {
        const newIssue: IWorkOrder = {
          ...contractor,
          createdAt: new Date(contractor.createdAt + 'Z').toLocaleString()
        };
        return newIssue;
      }))
      setIsLastPage(data?.isLastPage);
      setPageCount(data?.pageCount);
      setShimmered(false);
      clearMessage();
      const chartData = await getChartsData(abortController, 'workOrders', '');
      context.setSelectedTab('Work orders', chartData.values);
    } catch (error: any) {
      setErrorMessage(error.message);
    } finally {
      setIsInProgress(false);
    }
    return () => {
      abortController.abort();
    }
  }

  useEffect(() => {
    fetchData();
  }, [page.no, page.search, refreshCount])

  const [selectedWorkOrders, setSelectedWorkOrders] = useState<IWorkOrder[]>([]);
  const [resending, setResending] = useState(false);
  const [assigning, setAssigning] = useState(false);
  const notifValue = useAtomValue(notifAtom);

  useEffect(() => {
    if (notifValue.t === 'WOU') {
      const woId = (Number)(notifValue.b);
      setMessageBar(`WorkOrder ${woId} has been updated`, MessageBarType.warning,
        [{ actionLabel: 'Refresh', action: () => fetchData() }]);
    }
  }, [notifValue])

  const _items: ICommandBarItemProps[] = [
    {
      key: 'refresh',
      text: 'Refresh',
      iconProps: { iconName: 'Refresh' },
      onClick: () => { setRefreshCount(refreshCount + 1); },
    },
    {
      key: 'new',
      text: 'New',
      iconProps: { iconName: 'Add' },
      disabled:
        !profileData.roles.includes(appRoles.Admin) || resending,
      onClick: () => { setRefreshCount(refreshCount + 1); },
    },
    {
      key: 'edit',
      text: 'Edit',
      iconProps: { iconName: 'Edit' },
      disabled:
        !profileData.roles.includes(appRoles.Admin) ||
        selectedWorkOrders?.length !== 1 || resending,
      onClick: () => { setRefreshCount(refreshCount + 1); },
    },
    {
      key: "resend",
      text: "Resend",
      iconProps: { iconName: "FabricSyncFolder" },
      disabled:
        !profileData.roles.includes(appRoles.Admin) ||
        selectedWorkOrders?.length === 0 || resending,
      onClick: () => {
        const abortController = new AbortController();
        setResending(true);
        resendWorkOrder(abortController, selectedWorkOrders[0].id)
          .then((response: IResendWorkOrderResponse) => {
            alert(`Work order ${response.workOrderId} resent`);
          })
          .catch((error) => setErrorMessage(error.detail))
          .finally(() => setResending(false));
      },
    },
    {
      key: "assign",
      text: "Assign",
      iconProps: { iconName: "Assign" },
      disabled:
        !profileData.roles.includes(appRoles.Admin) ||
        selectedWorkOrders?.length === 0 || assigning,
      onClick: () => {
        showContractorSelector();
      },
    },
  ];

  const [isContractorSelectorOpen, { setTrue: showContractorSelector, setFalse: hideContractorSelector }] = useBoolean(false);
  const [contractors, setContractors] = useState<IContractor[]>([]);

  return (
    <>
      <Stack>
        <CommandBar
          items={_items}
          ariaLabel="Items actions"
          primaryGroupAriaLabel="Items actions"
          farItemsGroupAriaLabel="More actions"
        />
        <Stack.Item align="start" styles={scrollStackItemStyles}>
          <WorkItems issueWorkOrders={allItems} workOrderStatuses={workOrderStatuses}
            issue={undefined}
            listSelectionMode={SelectionMode.single}
            selection={selectedWorkOrders}
            setSelection={setSelectedWorkOrders}
            showStatusChangeMenu={true}
            shimmered={shimmered}></WorkItems>
        </Stack.Item>
        <Stack horizontal horizontalAlign="space-between">
          <Stack.Item grow={1} align="center">
            <IconButton
              iconProps={{ iconName: "DoubleChevronLeft" }}
              disabled={page.no === 1}
              onClick={() => setPage({ no: 1, search: page.search })}
            />
            <IconButton
              iconProps={{ iconName: "ChevronLeft" }}
              disabled={page.no === 1}
              onClick={() => setPage({ no: page.no - 1, search: page.search })}
            />
            <Text>
              {page.no} of {pageCount}
            </Text>
            <IconButton
              iconProps={{ iconName: "ChevronRight" }}
              disabled={isLastPage}
              onClick={() => setPage({ no: page.no + 1, search: page.search })}
            />
            <IconButton
              iconProps={{ iconName: "DoubleChevronRight" }}
              disabled={isLastPage}
              onClick={() => setPage({ no: pageCount, search: page.search })}
            />
          </Stack.Item>
        </Stack>
      </Stack>
      <Panel
        isLightDismiss
        isOpen={isContractorSelectorOpen}
        onDismiss={hideContractorSelector}
        closeButtonAriaLabel="Close"
        type={PanelType.medium}
        headerText="Pick contractor for selected work order"
      >
        <SearchBox
          placeholder="Search contractor"
          onSearch={(newValue) => {
            const abortController = new AbortController();
            getContractors(abortController, 10, 1, newValue)
              .then((contractors: IPagedCollection<IContractor>) => {
                setContractors(contractors.items)
              })
          }}
          onClear={() => setContractors([])} />
        <DetailsList
          columns={[
            {
              key: "Id",
              name: "Id",
              fieldName: "id",
              minWidth: 50,
              maxWidth: 50,
              isResizable: true,
              onRender: item => (
                <Link key={item.id} onClick={() => {
                  const abortController = new AbortController();
                  const workOrderAssignment: IWorkOrderAssignmentRequest = {
                    contractorId: item?.id,
                    workOrderId: selectedWorkOrders[0].id,
                    description: ''
                  };
                  createWorkOrderAssignment(abortController, workOrderAssignment)
                    // editIssue(abortController, selectedIssues[0].id, updatedIssue)
                    .then(() => {
                      hideContractorSelector();
                      setRefreshCount(refreshCount + 1);
                    });
                }}>
                  {item.id}
                </Link >
              )
            },
            {
              key: "Code",
              name: "Code",
              fieldName: "code",
              minWidth: 100,
              maxWidth: 125,
              isResizable: true,
            },
            {
              key: "Name",
              name: "Name",
              fieldName: "name",
              minWidth: 250,
              maxWidth: 250,
              isResizable: true,
            },
          ]}
          compact={true}
          items={contractors}
          selectionMode={SelectionMode.none}
        />
      </Panel>
    </>
  )
}

export default WorkOrders