import { ActionButton, Label, mergeStyleSets, Stack, TextField } from "@fluentui/react";
import { Dialog, DialogType } from "@fluentui/react/lib/Dialog";
import { useContext } from "react";

import AppContext from "./AppContext";
import { useAtom } from "jotai";
import { profileDataAtom } from "../atoms/authAtoms";
import { authorize, authorizeResponse } from "../services/assetServices";

const Profile = () => {
  const dialogContentProps = {
    type: DialogType.normal,
    title: "Profile",
    closeButtonAriaLabel: "Close",
  };

  const context = useContext(AppContext);
  const [profileData, setProfileData] = useAtom(profileDataAtom);
  const styles = mergeStyleSets({
    labelColumn: {
      width: '100px'
    },
  });
  return (
    <>
      <Dialog
        hidden={!context.isProfileDialogOpen}
        onDismiss={() => context.setIsProfileDialogOpen(false)}
        dialogContentProps={dialogContentProps}
      >
        <Stack>
          <Stack horizontal>
            <Label className={styles.labelColumn}>First Name: </Label>
            <TextField data-testid='firstNameText' readOnly value={profileData.givenName}></TextField>
          </Stack>
          <Stack horizontal>
            <Label className={styles.labelColumn}>Last Name: </Label>
            <TextField data-testid='lastNameText' readOnly value={profileData.surname}></TextField>
          </Stack>
          <Stack horizontal>
            <Label className={styles.labelColumn}>Email: </Label>
            <TextField data-testid='emailText' readOnly value={profileData.userPrincipalName}></TextField>
          </Stack>
          <Stack horizontal>
            <Label className={styles.labelColumn}>Id: </Label>
            <TextField data-testid='idText' readOnly value={profileData.id}></TextField>
          </Stack>
          <Stack>
            <ActionButton iconProps={{ iconName: 'Refresh' }}
              onClick={() => {
                const abortController = new AbortController();
                authorize(abortController, { username: profileData.userPrincipalName })
                  .then((data: authorizeResponse) => {
                    profileData.permissions = data.permissions;
                    profileData.tenants = data.tenants;
                    profileData.currentTenant = data.currentTenant;
                    setProfileData(profileData);
                  });
              }}
            >Reload Permissions</ActionButton>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default Profile;
