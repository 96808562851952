import {
  CommandBar,
  FontIcon,
  ICommandBarItemProps,
  IconButton,
  Link,
  mergeStyles,
  SearchBox,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import {
  ColumnActionsMode,
  DetailsListLayoutMode,
  IColumn,
  IDetailsColumnProps,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import { Selection } from "@fluentui/react/lib/DetailsList";
import { ShimmeredDetailsList } from "@fluentui/react/lib/ShimmeredDetailsList";
import { useBoolean } from "@fluentui/react-hooks";
import { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../AppContext";
import { getAssets, getAssetStatuses, getChartsData, getMetadata, IAsset, IAssetStatuses, IPagedCollection } from "../../services/assetServices";
import { useLocation, useNavigate } from "react-router-dom";
import { scrollStackItemStyles } from "../../common/styles/StackStyles";
import { appRoles } from "../../authConfig";
import React from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { errorMessageAtom, isInProgressAtom } from "../../atoms/messageBarAtoms";
import { currentTenantNameAtom, profileDataAtom } from "../../atoms/authAtoms";
import AssetSchedulePanel, { IAssetSchedulePanelRefType } from "./AssetSchedulePanel";
import { chatCommandContentsAtom, defaultChatCommand } from "../../atoms/chatCommandAtoms";
import { RenderIf } from "../../libs";

export interface IListItem {
  id: number;
  uuid: string;
  code: string;
  name: string;
}
export interface IListState {
  items: IListItem[];
  selectionDetails: string;
}

const sortIconClass = mergeStyles({
  fontSize: 12,
  paddingLeft: 5
});

const renderHeaderSortable = (props?: IDetailsColumnProps, defaultRender?: (props?: IDetailsColumnProps) => JSX.Element | null):
  JSX.Element | null => (!defaultRender ? <></> :
    <Stack horizontal verticalAlign="center">
      {defaultRender(props)}
      {props?.column.isSorted ? <></> :
        <FontIcon aria-label="Compass" iconName="Sort" className={sortIconClass} />
      }
    </Stack>);

const AssetsList = () => {
  let navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const action = query.get("action");

  const assetStatuses = useRef<IAssetStatuses[]>([]);
  const [allItems, setData] = useState<IAsset[]>([]);
  const [isLastPage, setIsLastPage] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState({ no: 1, search: "" });
  const [orderBy, setOrderBy] = useState<string>();
  const [shimmered, setShimmered] = useState(false);
  const context = useContext(AppContext);
  const setErrorMessage = useSetAtom(errorMessageAtom);
  const setIsInProgress = useSetAtom(isInProgressAtom);
  const profileData = useAtomValue(profileDataAtom);
  const [refreshCount, setRefreshCount] = useState(0);

  const setChatCommandContents = useSetAtom(chatCommandContentsAtom);

  const sortableColumnClicked = (ev: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn): void | undefined => {
    orderBy === column.fieldName ? setOrderBy(`${column.fieldName} Desc`) : setOrderBy(column.fieldName);
  };

  const _columns: IColumn[] = [
    {
      key: "Id",
      name: "Id",
      fieldName: "id",
      isSorted: orderBy?.startsWith("id"),
      isSortedDescending: orderBy === "id Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 45,
      maxWidth: 50,
      isResizable: true,
      onRender: item => (
        <Link key={item.assetId} onClick={() => navigate(`/assetDetail?itemId=${item.id}`)}>
          {item.id}
        </Link >
      )
    },
    {
      key: "Code",
      name: "Code",
      fieldName: "code",
      isSorted: orderBy?.startsWith("code"),
      isSortedDescending: orderBy === "code Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 75,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "category",
      name: "Category",
      fieldName: "category",
      isSorted: orderBy?.startsWith("category"),
      isSortedDescending: orderBy === "category Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
    },
    {
      key: "AssetDate",
      name: "Asset Date",
      fieldName: "changeDate",
      isSorted: orderBy?.startsWith("changeDate"),
      isSortedDescending: orderBy === "changeDate Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 100,
      maxWidth: 125,
      isResizable: true,
    },
    {
      key: "Name",
      name: "Name",
      fieldName: "name",
      isSorted: orderBy?.startsWith("name"),
      isSortedDescending: orderBy === "name Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 175,
      maxWidth: 250,
      isResizable: true,
    },
    {
      key: 'Status',
      name: "Status",
      fieldName: "statusId",
      minWidth: 75,
      maxWidth: 75,
      onRender: (item, index, column) => {
        const assetStatus = assetStatuses.current?.find(is => is.id === item.statusId);
        return (
          <Stack horizontal style={{
            borderBottomColor: `${assetStatus?.color}`,
            borderBlockStyle: 'solid',
            borderTopStyle: 'none'
          }}>
            <Stack.Item align="start">
              <span style={{ display: 'flex', justifyContent: 'left' }}>
                {assetStatus?.code}
              </span>
            </Stack.Item>
            <Stack.Item align="stretch" grow={1}><span style={{ width: '25px' }}></span> </Stack.Item>
            <Stack.Item align="end">
              <TooltipHost content={assetStatus?.name}>
                <FontIcon iconName={assetStatus?.icon} />
              </TooltipHost>
            </Stack.Item>
          </Stack>)
      }
    },
    {
      key: "zoneName",
      name: "Zone",
      fieldName: "zoneName",
      isSorted: orderBy?.startsWith("zoneName"),
      isSortedDescending: orderBy === "zoneName Desc",
      columnActionsMode: ColumnActionsMode.clickable,
      onRenderHeader: renderHeaderSortable,
      onColumnClick: sortableColumnClicked,
      minWidth: 150,
      maxWidth: 150,
      isResizable: true,
    },
  ];

  const columns = useRef<IColumn[]>(_columns);

  const _images: Map<string, string> = new Map<string, string>();

  const [isSchedulePanelOpen, { setTrue: showSchedulePanel, setFalse: hideSchedulePanel }] = useBoolean(false);
  const assetSchedulePanelRef = useRef<IAssetSchedulePanelRefType>(null);

  const currentTenantName = useAtomValue(currentTenantNameAtom);

  useEffect(() => {

    if (action === "schedule") {
      assetSchedulePanelRef.current?.fetchScheduledAction(context.selectedItem as IAsset, "schedule");
      showSchedulePanel();
    }

    const fetchData = async () => {
      context.setSelectedTab("Assets");
      const abortController = new AbortController();
      setIsInProgress(true);
      try {
        assetStatuses.current = await getAssetStatuses(abortController);
        const metadata = await getMetadata(abortController, 'Asset');
        const otherInfoFields = metadata?.fields.filter(mf => mf.showInGrid);

        const imagePaths: string[] = [];

        const data: IPagedCollection<IAsset> = await getAssets(abortController, 10, page.no, orderBy, page.search);

        const _assets = (data?.items?.map(asset => {
          const newAsset: any = {
            ...asset,
            changeDate: new Date(asset.changeDate + 'Z').toLocaleString(),
            modifiedAt: new Date(asset.modifiedAt + 'Z').toLocaleString(),
            modifiedBy: `${asset.modifiedBy ? `User Id: ${asset.modifiedBy}` : ''}`,
          };
          const otherInfoParts = asset.otherInfo?.split('|');
          if (otherInfoParts) {
            otherInfoFields?.forEach((mf, index) => {
              const part = otherInfoParts[index];
              newAsset[mf.name] = part;
              if (mf.fieldType === 'Image') {
                imagePaths.push(part.replace('attachments', 'thumbnails'));
              }
            });
          }
          otherInfoFields?.forEach(mf => {
            if (!columns.current?.find(column => column.name === mf.name)) {
              columns.current?.push({
                key: mf.name,
                name: mf.name,
                fieldName: mf.name,
                minWidth: 100,
                onRender: mf.fieldType === 'Image' ? (item: any) => (
                  <img src={_images.get(item[mf.name].replace('attachments', 'thumbnails'))} style={{ width: 20 }} alt="" />
                ) : mf.fieldType === 'Date' ? (item: any) => (
                  <span>{item[mf.name] ? new Date(item[mf.name]).toDateString() : ''}</span>
                ) : mf.fieldType === 'AutoComplete' ? (item: any) => (
                  <span>{(item[mf.name] ?? ":").split(':')[1]}</span>
                ) : undefined
              })
            }
          });

          return newAsset;
        }));

        setData(_assets)

        setIsLastPage(data?.isLastPage);
        setPageCount(data?.pageCount);
        setShimmered(false);
        const chartData = await getChartsData(abortController, 'assets', '');
        context.setSelectedTab('Assets', chartData?.values, { xAxisFormat: (x: string) => `${x.substring(0, 4)}/${x.substring(4)}` });
      }
      catch (error: any) {
        console.error("Error:", error);
        setErrorMessage(error.message);
      }
      finally {
        setIsInProgress(false);
      }
      return () => {
        abortController.abort();
      }
    };

    fetchData();
  }, [page.no, page.search, orderBy, currentTenantName, action, refreshCount]);

  const _items: ICommandBarItemProps[] = [
    {
      key: 'refresh',
      text: 'Refresh',
      iconProps: { iconName: 'Refresh' },
      onClick: () => { setRefreshCount(refreshCount + 1); },
    },
    {
      key: "newItem",
      text: "New",
      cacheKey: "myCacheKey", // changing this key will invalidate this item's cache
      iconProps: { iconName: "Add" },
      onClick: () => navigate("/newAsset"),
      disabled: !profileData.roles.includes(appRoles.Admin)
    },
    {
      key: "showQR",
      text: "Show QR",
      iconProps: { iconName: "QRCode" },
      onClick: () => {
        context.setSelectedItem({ id: 0, uuid: "" });
        navigate(`/showQR?itemId=${context.selectedItem.id}`);
      },
      disabled: context.selectedItem.uuid === "",
    },
    {
      key: "assetDetail",
      text: "Detail",
      iconProps: { iconName: "DocumentSet" },
      onClick: () => {
        context.setSelectedItem({ id: 0, uuid: "" });
        navigate(`/assetDetail?itemId=${context.selectedItem.id}`);
      },
      disabled: context.selectedItem.uuid === "",
    },
    {
      key: "assetEdit",
      text: "Edit",
      iconProps: { iconName: "Edit" },
      onClick: () => {
        context.setSelectedItem({ id: 0, uuid: "" });
        navigate(`/editAsset?id=${context.selectedItem.id}`);
      },
      disabled: !profileData.roles.includes(appRoles.Admin) || context.selectedItem.uuid === "",
    },
    {
      key: "schedule",
      text: "Schedule",
      iconProps: { iconName: "ScheduleEventAction" },
      onClick: () => {
        showSchedulePanel();
        assetSchedulePanelRef.current?.fetchScheduledAction(context.selectedItem as IAsset);
      },
      disabled: !profileData.roles.includes(appRoles.Admin) || context.selectedItem.uuid === "",
    },
    {
      key: "assetAudit",
      text: "Changes",
      iconProps: { iconName: "ComplianceAudit" },
      onClick: () => {
        navigate(`/assetAudit?itemId=${context.selectedItem.id}`);
      },
      disabled: !profileData.roles.includes(appRoles.Admin) || context.selectedItem.uuid === "",
    },
    {
      key: "assetDelete",
      text: "Delete",
      iconProps: { iconName: "Delete" },
      onClick: () => {
        context.setSelectedItem({ id: 0, uuid: "" });
        navigate(`/assetDetail?action=delete&itemId=${context.selectedItem.id}`);
      },
      disabled: !profileData.roles.includes(appRoles.Admin) || context.selectedItem.uuid === "",
    },
  ];

  const [uuid, setUuid] = useState("");
  const _selection = new Selection({
    onSelectionChanged: () => {
      const sel = _getSelectionDetails();
      // console.log(sel);
      setUuid(sel.message);
      if (sel.item) {
        context.setSelectedItem(sel.item);
      }
    },
  });

  const _getSelectionDetails = () => {
    const selectionCount = _selection.getSelectedCount();

    switch (selectionCount) {
      case 0:
        return { count: 0, message: "No items selected" };
      case 1: {
        const firstSelection = _selection.getSelection()[0] as IListItem;
        return {
          count: 1,
          id: firstSelection.id,
          item: firstSelection,
          message: firstSelection.uuid,
        };
      }
      default:
        return {
          count: selectionCount,
          message: `${selectionCount} items selected`,
        };
    }
  };

  return (
    <div>
      <Stack horizontal verticalAlign="center">
        <CommandBar
          items={_items}
          ariaLabel="Items actions"
          primaryGroupAriaLabel="Items actions"
          farItemsGroupAriaLabel="More actions"
        />
        <RenderIf condition={action !== "audit"}>
          <SearchBox
            placeholder="Search asset"
            onSearch={(newValue) => setPage({ no: 1, search: newValue || "" })}
            onClear={() => setPage({ no: 1, search: "" })} />
        </RenderIf>
      </Stack>
      <Stack style={{ overflowY: 'scroll' }}>
        <Stack.Item align="start" styles={scrollStackItemStyles} >
          {allItems ?
            <ShimmeredDetailsList
              items={allItems}
              columns={columns.current}
              setKey="set"
              layoutMode={DetailsListLayoutMode.fixedColumns}
              selection={_selection}
              selectionMode={SelectionMode.single}
              selectionPreservedOnEmptyClick={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
              enableShimmer={shimmered}
            /> : <></>}
        </Stack.Item>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Stack.Item grow={1} align="center">
          <IconButton
            iconProps={{ iconName: "DoubleChevronLeft" }}
            disabled={page.no === 1}
            onClick={() => setPage({ no: 1, search: page.search })}
          />
          <IconButton
            iconProps={{ iconName: "ChevronLeft" }}
            disabled={page.no === 1}
            onClick={() => setPage({ no: page.no - 1, search: page.search })}
          />
          <Text>
            {page.no} of {pageCount}
          </Text>
          <IconButton
            iconProps={{ iconName: "ChevronRight" }}
            disabled={isLastPage}
            onClick={() => setPage({ no: page.no + 1, search: page.search })}
          />
          <IconButton
            iconProps={{ iconName: "DoubleChevronRight" }}
            disabled={isLastPage}
            onClick={() => setPage({ no: pageCount, search: page.search })}
          />
        </Stack.Item>
      </Stack>
      <AssetSchedulePanel
        ref={assetSchedulePanelRef}
        isSchedulePanelOpen={isSchedulePanelOpen}
        hideSchedulePanel={() => {
          navigate('/');
          hideSchedulePanel();
          setChatCommandContents(defaultChatCommand);
        }}
      />
    </div>
  );
};

export default AssetsList;
