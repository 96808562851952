import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AppContext, {
  IDetailsListBasicExampleItem,
  IMessageBarAction,
  IMiniChartOptions,
} from "./components/AppContext";
import ShowQR from "./components/ShowQR";
import DetailAsset from "./components/assets/DetailAsset";
import Asset from "./components/assets/Asset";
import Home from "./components/Home";
import { FC, useEffect, useState } from "react";
import ImportAsset from "./components/import/ImportAsset";
import Profile from "./components/Profile";
import { useIsAuthenticated as useIsAuthenticatedMSAL } from "@azure/msal-react";
import { MessageBarType, PrimaryButton } from "@fluentui/react";
import { appRoles, idp, idps, loginRequest } from "./authConfig";
import ItemsList from "./components/assets/AssetsList";
import QrCodes from "./components/QrCodes";
import Settings from "./components/settings/Settings";
import Issues from "./components/issues/Issues";
import WorkOrders from "./components/workOrders/WorkOrders";
import Zones from "./components/zones/Zones";
import Categories from "./components/Categories";
import ImportIssueTypes from "./components/import/ImportIssueTypes";
import ImportZones from "./components/import/ImportZones";
import Contractors from "./components/contractors/Contractors";
import Contractor from "./components/contractors/Contractor";
import Issue from "./components/issues/Issue";
import Login from "./Login";
import Statuses from "./components/Statuses";
import PermissionsPivot from "./components/permissions/PermissionsPivot";
import Reports from "./components/reports/Reports";
import { Metadata } from "./components/settings/Metadata";
import AssetsChanges from "./components/assets/AssetsChanges";

const App: FC<any> = ({ instance }) => {

  const defaultItem: IDetailsListBasicExampleItem = {
    id: 0,
    uuid: "",
    code: "",
    name: "",
  };

  interface IMessage {
    message: string;
    messageType: MessageBarType;
    actions?: IMessageBarAction[]
  }

  const [selectedItem, setSelectedItem] =
    useState<IDetailsListBasicExampleItem>(defaultItem);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [message] = useState<IMessage>({ message: '', messageType: MessageBarType.info, actions: [] });
  const [selectedTab, setSelectedTab] = useState("");
  const [miniChartData, setMiniChartData] = useState<Map<string, number>>();
  const [miniChartOptions, setMiniChartOptions] = useState<IMiniChartOptions>();
  const context = {
    selectedItem: selectedItem,
    setSelectedItem: (item: IDetailsListBasicExampleItem) => {
      setSelectedItem(item);
    },
    isImportDialogOpen: isImportDialogOpen,
    setIsImportDialogOpen: (flag: boolean) => {
      setIsImportDialogOpen(flag);
    },
    isProfileDialogOpen: isProfileDialogOpen,
    setIsProfileDialogOpen: (flag: boolean) => {
      setIsProfileDialogOpen(flag);
    },
    isAuthenticated: isAuthenticated,
    setIsAuthenticated: (flag: boolean) => {
      setIsAuthenticated(flag);
    },
    actions: message.actions ?? [],
    setSelectedTab: (selectedTab: string, miniChartData?: Map<string, number>, miniChartOptions?: IMiniChartOptions) => {
      setSelectedTab(selectedTab);
      setMiniChartData(miniChartData ?? new Map<string, number>());
      setMiniChartOptions(miniChartOptions);
    },
    selectedTab: selectedTab,
    miniChartData: miniChartData ?? new Map<string, number>(),
    miniChartOptions: miniChartOptions ?? { xAxisFormat: (x: string) => x },
  };
  const [showLoginPage, setShowLoginPage] = useState(false);

  const handleSignIn = () => {
    if (idp === idps.AAD) {
      instance.loginRedirect(loginRequest)
        .catch((e: any) => {
          console.error(e);
        });
      context.setIsAuthenticated(true);
    } else if (idp === idps.DB) {
      setShowLoginPage(true);
    }
  };

  const handleSignOut = () => {
    if (idp === idps.AAD) {
      instance.logoutRedirect().catch((e: any) => {
        console.error(e);
      });
    } else if (idp === idps.DB) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  };

  // const accounts = instance.getAllAccounts();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const onLoad = async () => {
      if (idp === idps.AAD) {
        const currentAccount: any = instance.getActiveAccount();

        if (currentAccount && currentAccount.idTokenClaims["roles"]) {
          let intersection = [appRoles.Admin, appRoles.Viewer].filter((role: any) =>
            currentAccount.idTokenClaims["roles"].includes(role)
          );

          if (intersection.length > 0) {
            setIsAuthorized(true);
          }
        }
      } else {
        // to be completed for DB idp
        setIsAuthorized(true);
      }
    };
    onLoad();
  }, [instance]);

  const isAuthenticatedInAad = useIsAuthenticatedMSAL();

  return (
    <AppContext.Provider value={context}>
      {(isAuthenticatedInAad || context.isAuthenticated) ? (
        <>
          <BrowserRouter basename="/">
            {isAuthorized ? (
              <>
                <Home>
                  <Routes>
                    <Route path="/" element={<ItemsList />} />
                    <Route path="/assets" element={<ItemsList />} />
                    <Route path="/assetAudit" element={<AssetsChanges />} />
                    <Route path="/zones" element={<Zones />} />
                    <Route path="/categories" element={<Categories />} />
                    <Route path="/contractors" element={<Contractors />} />
                    <Route path="/newContractor" element={<Contractor />} />
                    <Route path="/editContractor" element={<Contractor />} />
                    <Route path="/newAsset" element={<Asset />} />
                    <Route path="/showQR" element={<ShowQR />} />
                    <Route path="/assetDetail" element={<DetailAsset />} />
                    <Route path="/issues" element={<Issues />} />
                    <Route path="/newIssue/:by" element={<Issue />} />
                    <Route path="/editIssue" element={<Issue />} />
                    <Route path="/editAsset" element={<Asset />} />
                    <Route path="/importAssets" element={<ImportAsset />} />
                    <Route path="/importIssueTypes" element={<ImportIssueTypes />} />
                    <Route path="/importZones" element={<ImportZones />} />
                    <Route path="/workOrders" element={<WorkOrders />} />
                    <Route path="/qrCodes" element={<QrCodes />} />
                    <Route path="/permissions" element={<PermissionsPivot />} />
                    <Route path="/metadata" element={<Metadata />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/statuses/:entityType" element={<Statuses />} />
                    <Route path="/reports" element={<Reports />} />
                  </Routes>
                </Home>
              </>
            ) : (
              <div className="App">
                <p>
                  Your account is not permitted to use this application. Contact
                  administrator or try another account.
                </p>
                <PrimaryButton onClick={handleSignOut}>Sign out</PrimaryButton>
              </div>
            )}
          </BrowserRouter>
          <Profile />
        </>) : (
        <>
          {showLoginPage ? <Login onLoginSuccess={() => { setIsAuthenticated(true) }} /> :
            <div className="App">
              <p>You are not signed in! Please sign in.</p>
              <PrimaryButton onClick={handleSignIn}>Sign in</PrimaryButton>
            </div>}
        </>)}
    </AppContext.Provider>
  );
};

export default App;
