import { IStackStyles, Stack, Pivot, PivotItem } from '@fluentui/react'
import { useNavigate } from 'react-router-dom'
import Groups from './Groups';
import Users from './Users';
import Permissions from './Permissions';
import Tenants from './Tenants';

const PermissionsPivot = () => {
  const navigate = useNavigate();
  const stackStyles: IStackStyles = {
    root: {
      label: {
        display: 'flex',
        paddingLeft: 10
      }
    }
  }

  return (
    <Stack styles={stackStyles} horizontalAlign="start">
      <Pivot defaultSelectedKey='users' onLinkClick={(item) => {
        if (item?.props.itemKey === 'back') {
          navigate('/');
        }
      }}>
        <PivotItem itemIcon='ChromeBack' itemKey='back' />
        <PivotItem headerText="Users" itemKey='users'>
          <Users />
        </PivotItem>
        <PivotItem headerText="Groups">
          <Groups />
        </PivotItem>
        <PivotItem headerText="Permissions">
          <Permissions />
        </PivotItem>
        <PivotItem headerText="Tenants">
          <Tenants />
        </PivotItem>
      </Pivot>
    </Stack>)
}

export default PermissionsPivot;